.Container{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: darkolivegreen;
}
.child-container{
    height: 80vh;
    width: 70vw;
    background-color: darkgrey;
    display: grid;
    grid-template-columns: 20% 80%;
}