
.Main{
    display: grid;
    padding: 1rem;
    grid-template-rows: 70% 30%;
    overflow: hidden;
    background-color: darkorchid;
}
.contacts-display{
    display: flex;
    flex-direction:column;
    align-items:center;
    overflow:auto;
    gap: 0.4rem;
}
.currentUser{
    align-items: center;
    text-align: center;
    background-color: darkkhaki;
    
}
.contact{
    background-color: darkcyan;
    width: 80%;
    border-radius: 5%;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: auto;
}
.selected{
    background-color: brown;
}