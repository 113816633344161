 .FormContainer{
  
  height: 100vh;
  width: 100vw; 
   display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color:gray; 
} 
  .brand {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
  }
  .brand img {
      height: 5rem;
    }
  .brand h1 {
      color: white;
      text-transform: uppercase;
    }
  

  .form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #00000076;
    border-radius: 2rem;
    padding: 3rem 5rem;
  }
  input {
    background-color: transparent;
    padding: 1rem;
    border: 0.1rem solid #4e0eff;
    border-radius: 0.4rem;
    color: white;
    width: 100%;
    font-size: 1rem;
  }

  
  button {
    background-color: #4e0eff;
    color: white;
    padding: 1rem 2rem;
    border: none;
    font-weight: bold;
    cursor: pointer;
    border-radius: 0.4rem;
    font-size: 1rem;
    text-transform: uppercase;
   
  }
  span {
    color: white;
    text-transform: uppercase;
   
  } 
  .btn{
      background-color: aquamarine;
      border-radius: 10%;
      color:gray;
      font-weight: bold;
  }
